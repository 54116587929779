// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-escola-bem-vindo-js": () => import("./../../../src/pages/a-escola/bem-vindo.js" /* webpackChunkName: "component---src-pages-a-escola-bem-vindo-js" */),
  "component---src-pages-a-escola-desafios-e-festivais-js": () => import("./../../../src/pages/a-escola/desafios-e-festivais.js" /* webpackChunkName: "component---src-pages-a-escola-desafios-e-festivais-js" */),
  "component---src-pages-a-escola-formaturas-js": () => import("./../../../src/pages/a-escola/formaturas.js" /* webpackChunkName: "component---src-pages-a-escola-formaturas-js" */),
  "component---src-pages-a-escola-internacionalizacao-js": () => import("./../../../src/pages/a-escola/internacionalizacao.js" /* webpackChunkName: "component---src-pages-a-escola-internacionalizacao-js" */),
  "component---src-pages-a-escola-para-o-estudante-js": () => import("./../../../src/pages/a-escola/para-o-estudante.js" /* webpackChunkName: "component---src-pages-a-escola-para-o-estudante-js" */),
  "component---src-pages-a-escola-quem-somos-js": () => import("./../../../src/pages/a-escola/quem-somos.js" /* webpackChunkName: "component---src-pages-a-escola-quem-somos-js" */),
  "component---src-pages-acontece-na-escola-js": () => import("./../../../src/pages/acontece-na-escola.js" /* webpackChunkName: "component---src-pages-acontece-na-escola-js" */),
  "component---src-pages-beneficios-js": () => import("./../../../src/pages/beneficios.js" /* webpackChunkName: "component---src-pages-beneficios-js" */),
  "component---src-pages-contraturno-atividades-extracurriculares-js": () => import("./../../../src/pages/contraturno/atividades-extracurriculares.js" /* webpackChunkName: "component---src-pages-contraturno-atividades-extracurriculares-js" */),
  "component---src-pages-contraturno-oficinas-js": () => import("./../../../src/pages/contraturno/oficinas.js" /* webpackChunkName: "component---src-pages-contraturno-oficinas-js" */),
  "component---src-pages-etapas-de-aprendizagem-cursos-tecnicos-js": () => import("./../../../src/pages/etapas-de-aprendizagem/cursos-tecnicos.js" /* webpackChunkName: "component---src-pages-etapas-de-aprendizagem-cursos-tecnicos-js" */),
  "component---src-pages-etapas-de-aprendizagem-educacao-infantil-js": () => import("./../../../src/pages/etapas-de-aprendizagem/educacao-infantil.js" /* webpackChunkName: "component---src-pages-etapas-de-aprendizagem-educacao-infantil-js" */),
  "component---src-pages-etapas-de-aprendizagem-ensino-fundamental-js": () => import("./../../../src/pages/etapas-de-aprendizagem/ensino-fundamental.js" /* webpackChunkName: "component---src-pages-etapas-de-aprendizagem-ensino-fundamental-js" */),
  "component---src-pages-etapas-de-aprendizagem-ensino-medio-js": () => import("./../../../src/pages/etapas-de-aprendizagem/ensino-medio.js" /* webpackChunkName: "component---src-pages-etapas-de-aprendizagem-ensino-medio-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-matriculas-js": () => import("./../../../src/pages/matriculas.js" /* webpackChunkName: "component---src-pages-matriculas-js" */),
  "component---src-pages-rematriculas-js": () => import("./../../../src/pages/rematriculas.js" /* webpackChunkName: "component---src-pages-rematriculas-js" */),
  "component---src-templates-ambientes-de-aprendizagem-js": () => import("./../../../src/templates/ambientes-de-aprendizagem.js" /* webpackChunkName: "component---src-templates-ambientes-de-aprendizagem-js" */)
}

